// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import "chartkick/chart.js"
import * as ActiveStorage from "@rails/activestorage"

require("behavior_tracking_preferences_form")
require("behavior_tracking_edit/sum_update")
require("behavior_tracking_edit/counters")

Rails.start()
Turbolinks.start()
ActiveStorage.start()

document.addEventListener("turbolinks:load", function() {
  $(".profile-nav-open").click(function() {
    $(".profile-navigation").toggleClass("is-open")
  })
})

document.addEventListener("turbolinks:load", function() {
  setTimeout(function(){
    $('.flash-message').slideUp(500);
  }, 1000);
})
