export const sumBtFields = function() {
  var btCounts = []
  
  $(".bt-counter-field").each(function() {
    const num = Math.abs(Number($(this).val()))
    btCounts.push(num)
  })
  var btSum = btCounts.reduce(function(previousValue, currentValue) {
    return previousValue + currentValue
  }, 0)
  
  return btSum
}

export const updateBtSum = function() {
  $('#bt-total').html(sumBtFields())
}
