import  { updateBtSum } from "./sum_update"

document.addEventListener("turbolinks:load", function () {
  $("body").on("change", ".bt-counter-field", function () {
    updateBtSum()
  })

  $(".number-button").on("click", function() {
    var input = $(this).siblings("input")
    var num = Number(input.val())
    var operator = $(this).data("operator")

    if (operator === "minus" && num > 0) {
      input.val(num - 1)
    } else if (operator === "plus") {
      input.val(num + 1)
    }
    
    updateBtSum()
  })
})
